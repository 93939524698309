import React from 'react';

import { Box, CssBaseline, SxProps, Theme } from '@mui/material';

import { Outlet } from 'react-router-dom';

const containerStyle: SxProps<Theme> = {
  backgroundColor: '#f3f3f4',
  maxWidth: 'unset',
  height: '100vh',
  top: 0,
  bottom: 0,
};

/**
 * Blank layout
 * @return {JSX.Element}
 */
function BlankLayout(): JSX.Element {
  return (
    <>
      <CssBaseline />
      <Box id="blankLayout" sx={containerStyle}>
        <Outlet />
      </Box>
    </>
  );
};

export default BlankLayout;
