import { Localization, ptBR } from '@mui/material/locale';
import intl from 'react-intl-universal';
import { UTILS } from './constants';

export const CURRENT_LOCALE_KEY = 'sop_ii_currentLocale';
export const DEFAULT_LANGUAGE = 'pt-BR';
export const DEFAULT_LANGUAGE_SUFIX = 'pt';

 type Locales = {
   [key: string]: string
 }

 type ThemeLocales = {
   [key: string]: Localization
 }

const locales: Locales = {
  'pt-BR': require('locales/pt-BR.json'),
};

/**
  * Get locale for Material theme
  * @return {Localization}
  */
export function getThemeLocale(): Localization {
  const muiLocales: ThemeLocales = {
    'pt-BR': ptBR,
  };

  return muiLocales[navigator.language] || muiLocales[DEFAULT_LANGUAGE];
};

/**
  * Initialize internationalization
  * @return {void}
  */
export function initIntl(): void {
  const locale: string = initLocale();
  const currentLocale = locale === 'pt' ? 'pt-BR' : 'en-US';
  intl.init({ currentLocale, locales });
};

/**
  * Init locale
  * @return {string}
  */
function initLocale(): string {
  let currentLocaleSufix: string = getLocaleSufix();

  if (!currentLocaleSufix) {
    currentLocaleSufix = DEFAULT_LANGUAGE_SUFIX;
  }

  localStorage.setItem(CURRENT_LOCALE_KEY, currentLocaleSufix);

  return currentLocaleSufix;
}

/**
  * Set locale string
  */
export function setLocaleString(): void {
  const currentLocale: string = getLocaleSufix();
  const updateLocale = currentLocale === 'pt' ? 'en' : 'pt';

  localStorage.setItem(CURRENT_LOCALE_KEY, updateLocale);

  window.location.reload();
}

/**
  * Get locale string
  * @return {string}
  */
export function getLocaleSufix(): string {
  return localStorage.getItem(CURRENT_LOCALE_KEY) || UTILS.BLANK;
}

export const getModelLocaleString = <T extends {[key: string]: any}>(model: T, key: string) => {
  const sufix: string = getLocaleSufix();
  const object: {[key: string]: any} = { ...model };

  return object[`${key}_${sufix}`];
};
