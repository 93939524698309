import React, { CSSProperties } from 'react';

import { useMediaQuery } from '@mui/material';

import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import defaultTheme from 'shared/theme';

import 'components/Toast/index.css';

const toastStyle = (smDown: boolean): CSSProperties => ({
  alignItems: 'center',
  width: smDown ? '100%' : 'max-content',
  minWidth: smDown ? 'unset' : '450px',
  minHeight: '80px',
});

const containerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

/**
 * Toast Container component
 * @return {JSX.Element}
 */
function Container(): JSX.Element {
  const smDown = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return <ToastContainer
    hideProgressBar
    position='top-center'
    toastStyle={toastStyle(smDown)}
    style={containerStyle}
    transition={Slide}
    autoClose={3000}
    closeOnClick={true}
    closeButton={false}
    pauseOnFocusLoss={false}
    pauseOnHover={false}
    draggable={false}
    icon={false}
    limit={5}
  />;
}

export default Container;
