import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

import intl from 'react-intl-universal';

import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';

/**
 * NotFound component
 * @return {JSX.Element}
 */
const NotFound = (): JSX.Element => {
  const navigate = useNavigate();
  // TODO: ajust for mobile
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
      height: `100%`,
    }}
    >
      <Typography variant="h4">404</Typography>
      <Typography variant="subtitle1">
        {intl.get('pages.notFound.title')}
      </Typography>
      <Button
        color="primary"
        aria-label="home"
        onClick={() => navigate(ROUTES.ROOT)}
      >
        <HomeIcon />
      </Button>
    </Box>
  );
};

export default NotFound;
